import axios from "axios";

const APICreateUser = (userData) => {
    try {
        return axios.post(`/api/users/providers/${userData.provider_id}`, userData);
    } catch (e) {
        return e.response.data;
    }
}

const APIChangeUserData = (userData) => {
    try {
        let {phones, notification, role, id, provider_id} = {...userData}

        return axios.put(`/api/users/${id}/providers/${provider_id}`, {phones, notification, role});
    } catch (e) {
        return e.response.data;
    }
}

const APIRemoveUser = (userData) => {
    try {
        let {id, provider_id} = {...userData};
        return axios.delete(`/api/users/${id}/providers/${provider_id}`)
    } catch (e) {

    }
}
export const CreateUserByProvider = (user, callback_func) => {
    const response = APICreateUser(user);

    response.then(val => val.status === 200 || val.status === 204 || val.status === 201 ? callback_func('added') : callback_func('failed'));
}

export const ChangeUserByProvider = (user, callback_func) => {
    const response = APIChangeUserData(user);

    response.then(val => val.status === 200 || val.status === 204 || val.status === 201 ? callback_func('added') : callback_func('failed'));
}

export const RemoveUserByProvider = (user, callback_func) => {
    const response = APIRemoveUser(user);

    response.then(val => val.status === 200 || val.status === 204 || val.status === 201 ? callback_func('removed') : callback_func('failed'));
}

