<template>
  <div v-if="this.$store.state.organization.id !== 0 && can('provider_user')">
    <CRow class="w-100 justify-content-center font-sm ">
      <CCol col="11" sm="11" xl="4" md="9" lg="4">
        <transition name="slide">
          <CCard>
            <CCardHeader data-cy="nameOrganization">{{ $store.state.organization.name }}</CCardHeader>
            <CCardBody data-cy="blockOrganizationBody">
              <div v-if="$store.state.organization.ipn" class="row border-bottom mb-2">
                <p class="col-9 col-sm-8 col-md-8 col-lg-5 col-xl-7 font-weight-bold">ІПН</p>
                <p class="col-9 col-sm-4 col-md-4 col-lg-7 col-xl-5">{{
                    $store.state.organization.ipn
                  }}</p>
              </div>
              <div v-else class="row border-bottom mb-2">
                <p class="col-9 col-sm-8 col-md-8 col-lg-5 col-xl-7 font-weight-bold">ЄДРПОУ</p>
                <p class="col-9 col-sm-4 col-md-4 col-lg-7 col-xl-5">
                  {{ $store.state.organization.edr }}</p>
              </div>
              <div class="row border-bottom mb-2">
                <p class="col-9 col-sm-8 col-md-8 col-lg-5 col-xl-7 font-weight-bold">Бренд</p>
                <p class="col-9 col-sm-4 col-md-4 col-lg-7 col-xl-5">{{
                    $store.state.organization.brand
                  }}</p>
              </div>
              <div class="row border-bottom mb-2">
                <p class="col-9 col-sm-8 col-md-8 col-lg-5 col-xl-7 font-weight-bold">Веб-сторінка</p>
                <p class="col-9 col-sm-4 col-md-4 col-lg-7 col-xl-5">
                  {{ $store.state.organization.website }}</p>
              </div>
              <div class="row border-bottom mb-2">
                <p class="col-9 col-sm-8 col-md-8 col-lg-5 col-xl-7 font-weight-bold">Телефон
                  абон.відділу/бухгалтерії</p>
                <div class="col-9 col-sm-4 col-md-4 col-lg-7 col-xl-5">
                  <ul style="list-style-type: none" class="p-0">
                    <li v-for="tel in $store.state.organization.accounting_contacts" :key="tel">
                      <a :href="'tel://'+tel">
                        {{ tel }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row border-bottom mb-2">
                <p class="col-9 col-sm-8 col-md-8 col-lg-5 col-xl-7 font-weight-bold">Email
                  абон.відділу/бухгалтерії</p>
                <div class="col-9 col-sm-4 col-md-4 col-lg-7 col-xl-5">
                  <ul style="list-style-type: none" class="p-0">
                    <li v-for="mail in $store.state.organization.accounting_emails" :key="mail">
                      <a :href="'mailto:' + mail">
                        {{ mail }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row border-bottom mb-2">
                <p class="col-9 col-sm-8 col-md-8 col-lg-5 col-xl-7 font-weight-bold">Телефон
                  Тех.підтримки</p>
                <div class="col-9 col-sm-4 col-md-4 col-lg-7 col-xl-5">
                  <ul style="list-style-type: none" class="p-0">
                    <li v-for="tel in $store.state.organization.technical_support_contacts"
                        :key="tel">
                      <a :href="'tel://'+tel">
                        {{ tel }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row border-bottom mb-2">
                <p class="col-9 col-sm-8 col-md-8 col-lg-5 col-xl-7 font-weight-bold">Email
                  Тех.підтримки</p>
                <template class="col-9 col-sm-4 col-md-4 col-lg-7 col-xl-5">
                  <ul style="list-style-type: none" class="p-0">
                    <li v-for="mail in $store.state.organization.technical_emails" :key="mail">
                      <a :href="'mailto:' + mail">
                        {{ mail }}
                      </a>
                    </li>
                  </ul>
                </template>
              </div>
              <div class="row border-bottom mb-2">
                <p class="col-9 col-sm-8 col-md-8 col-lg-5 col-xl-7 font-weight-bold">Надання
                  магістральних каналів</p>
                <p class="col-9 col-sm-4 col-md-4 col-lg-7 col-xl-5">
                  {{ $store.state.organization.is_pipeline ? "Так" : "Ні" }}</p>
              </div>
              <div class="row">
                <p class="col-9 col-sm-8 col-md-8 col-lg-5 col-xl-7 font-weight-bold">Ел.документообіг
                  PaperLess</p>
                <p class="col-9 col-sm-4 col-md-4 col-lg-7 col-xl-5">
                  {{ $store.state.organization.is_paper_less ? "Так" : "Ні" }}</p>
              </div>
            </CCardBody>
            <CCardFooter>
              <CCol sm="12" class="mb-3 text-right">
                <CButton color="primary" variant="ghost" @click="editInfo">Редагувати</CButton>
              </CCol>
            </CCardFooter>
          </CCard>
        </transition>
        <template v-if="regions !== null">
          <transition name="slide">
            <CCard data-cy="mapRegions">
              <CCardHeader>
                Карта присутності
              </CCardHeader>
              <CCardBody>
                <multiselect
                    v-model="$store.state.regions"
                    :show-labels="false"
                    :options="regions"
                    :multiple="true"
                    track-by="id"
                    label="region"
                >
                  <span slot="noResult">Не знайдено</span>
                </multiselect>
                <CRow>
                  <CCol col="6" lg="9" class="p-md-2 text-left">
                    <CButton color="primary" variant="ghost" @click="selectAllRegions">Вся Україна
                    </CButton>
                  </CCol>
                  <CCol col="6" lg="3" class="p-md-2 text-right">
                    <CButton
                        v-if="loading_btn_region"
                        color="primary"
                        class="w-100"
                        disabled
                    >
                      <div class="  align-items-center">
                        <CSpinner color="default" size="sm"/>
                      </div>
                    </CButton>
                    <CButton
                        v-else
                        class="w-100 "
                        @click="saveRegions"
                        color="primary"
                        variant="ghost"
                    >
                      Зберегти
                    </CButton>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </transition>
        </template>
      </CCol>
      <CCol v-show="$store.state.users !== undefined" col="11" xl="8" lg="8" md="9">
        <CCard data-cy="organizationUsers">
          <CCardHeader>
            Користувачі
          </CCardHeader>
          <CCardBody>
            <provider-users-details-grid :users="$store.state.users" @rowClicked="rowClicked"/>

            <CCol v-if="this.$store.state.login_info.role === 'Адмін'" col="12" class="p-md-2 text-right">
              <CButton
                  color="primary"
                  variant="ghost"
                  @click="showModalProviderUser(true, 'CreateUserByProvider')"
              >Додати користувача
              </CButton>
            </CCol>
          </CCardBody>
          <modal-create-provide-user
              :showCreateUser="showModalUserData"
              :providerID="$store.state.organization.id"
              :funcAddUser="selectedFunction[apiNameModalUserData]"
              :funcRemoveUser="selectedFunction['RemoveUserByProvider']"
              :changesUser="userData"
              :clickableRows="true"
              :title="!!userData ? 'Редагування даних користувача' : 'Створення користувача'"
              @updateValue="updateUsers()"
              @closing="showModalProviderUser()"/>
        </CCard>
        <CCard data-cy="currentProposals">
          <CCardHeader>Ділова активність</CCardHeader>
          <CCardBody>
            <template v-if="currentProposals !== null">
              <provider-current-proposals-grid :provider-proposals="currentProposals"
                                               :clickableRows="true" @rowClicked="showTenderDetails"/>
            </template>
            <template v-else>
              <h4>Ви ще не подали жодної комерційної пропозиції</h4>
            </template>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect';
import statusTenderAndPropositionMixin from '../../mixins/statusTenderAndPropositionMixin';
import axios from "axios";
import ModalCreateProvideUser from "../Components/Modal/ModalCreateProvideUser.vue";
import {CreateUserByProvider, ChangeUserByProvider, RemoveUserByProvider,} from '../../api/createUserByProvider';
import ProviderUsersDetailsGrid from "../Components/Grid/ProviderUsersDetailsGrid.vue";
import ProviderCurrentProposalsGrid from "../Components/Grid/ProviderCurrentProposalsGrid.vue";
import {routerGoToName} from "@/utils/routerUtils";
import {can} from "@/rules/permissions";

export default {
  mixins: [statusTenderAndPropositionMixin],
  components: {
    ModalCreateProvideUser,
    Multiselect,
    ProviderUsersDetailsGrid,
    ProviderCurrentProposalsGrid
  },
  name: 'ProviderInfo',
  data: () => {
    return {
      showModalUserData: false,
      apiNameModalUserData: undefined,
      userData: undefined,
      accounting_contacts: [],
      regions: [],
      currentProposals: null,
      loading_btn_region: false,
      selectedFunction: {
        RemoveUserByProvider,
        CreateUserByProvider,
        ChangeUserByProvider,
      }
    }
  },
  created() {
    this.$store.dispatch('GET_REGIONS');
    this.$store.dispatch('GET_ORGANIZATION');
    this.updateUsers();
    this.getRegions();
    this.getCurrentProposals();
  },
  methods: {
    can,
    updateUsers() {
      this.$store.dispatch('GET_USERS');
    },
    showModalProviderUser(show = false, funcName = undefined, user = undefined) {
      this.showModalUserData = show;
      this.apiNameModalUserData = funcName;
      this.userData = user;
      if (!show) this.updateUsers();
    },
    editInfo() {
      // this.$router.push({path: `provider/organization/edit`});
      routerGoToName('ProviderOrganizationEdit');
    },
    selectAllRegions() {
      this.$store.commit('updateRegions', this.regions)
      this.$store.state.regions = this.regions
    },
    rowClicked(item) {
      this.showModalProviderUser(true, 'ChangeUserByProvider', item)
    },
    showTenderDetails(item) {
      console.log(item)
      routerGoToName('TendersPreview', 'params', {id: item.order_id.toString()});
      // this.$router.push({path: `/tenders/` + item.order_id.toString()})
    },
    getRegions() {
      let self = this;
      axios.get(
          '/api/regions')
          .then(function (response) {
            self.regions = response.data;
          })
          .catch(function () {

          });
    },
    saveRegions() {
      let self = this;
      self.loading_btn_region = true;
      axios.post(
          '/api/account/regions', self.$store.state.regions)
          .then(function (response) {
            self.loading_btn_region = false;
            self.$store.commit('updateRegions', response.data)
            self.$store.state.regions = response.data
          })
          .catch(function () {
            self.loading_btn_region = false;
          });
    },
    getCurrentProposals() {
      let self = this;
      axios.get('/api/account/offers/active')
          .then(function (response) {
            self.currentProposals = response.data;
          }).catch();
    }
  },
  watch: {
    "$store.state.organization.id": function () {
      this.getCurrentProposals()
    }
  },
}
</script>
