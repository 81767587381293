<template>
    <CDataTable
            hover
            striped
            :items="Array.isArray(providerProposals) ? providerProposals : Array(providerProposals)"
            :fields="fieldsProviderProposals"
            :items-per-page="perPage"
            :pagination="paginationProps"
            index-column
            :clickable-rows="clickableRows"
            :noItemsView="{ noResults: 'За результатами пошуку нічого не знайдено', noItems: 'no items available' }"
            @row-clicked="rowClicked"
    >
        <template #branch_a="data">
            <td>
                <span class="font-weight-bold">{{ data.item.branch_a }}</span>
                {{ data.item.branch_a_address }}
            </td>
        </template>
        <template #status="data">
            <td>
                <CBadge class="p-1" :color="badgeStatusColor(data.item.status)">
                    {{ statusName(data.item.status) }}
                </CBadge>
            </td>
        </template>
        <template #status_order="data">
            <td>
                <CBadge class="p-1" :color="getBadgeStatus(data.item.status_order)">
                    {{ statusTender(data.item.status_order) }}
                </CBadge>
            </td>
        </template>
        <template #download_speed="data">
            <td>
                {{ data.item.download_speed }}/{{ data.item.upload_speed }}
            </td>
        </template>
    </CDataTable>
</template>
<script>
import {fieldsProviderProposals, paginationProps} from "./Fields";
import statusTenderAndPropositionMixin from "../../../mixins/statusTenderAndPropositionMixin";

export default {
    mixins: [statusTenderAndPropositionMixin],
    emits: ['rowClicked'],
    props: {
        providerProposals: {
            required: true,
            type: [Array, Object],
            default: null
        },
        perPage: {
            type: Number,
            required: false,
            default: 5
        },
        clickableRows: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            fieldsProviderProposals,
            paginationProps
        }
    },
    methods: {
        rowClicked(item) {
            this.$emit('rowClicked', item);
        },
        badgeStatusColor(status) {
            switch (status) {
                case 'ProposalSubmitted':
                    return 'success';
                case 'Winner':
                    return 'success';
                case 'Participated':
                    return 'warning';
                case 'Refusal':
                    return 'dark';
                default:
                    return 'primary';
            }
        },
        statusName(status) {
            switch (status) {
                case 'ProposalSubmitted':
                    return 'Пропозиція надіслана';
                case 'Winner' :
                    return 'Переможець';
                case 'Participated' :
                    return 'Брав участь';
                case 'Refusal' :
                    return 'dark';
                default:
                    return 'Відмінений';
            }
        },
    }
}
</script>